import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Listen from "../components/Listen"
import Weather from "../components/Weather"
import Waves from "../components/Waves"
import DealsOnWheels from "../components/DealsOnWheels"
import NicePlace from "../components/NicePlace"
import TodayInLA from "../components/TodayInLA"
import DirtyDogs from "../components/DirtyDogs"
import CoolPool from "../components/CoolPool"
import DinersClub from "../components/DinersClub"

const IndexPage = () => (
  <Layout>
    <SEO />
    <div className="flex flex-col md:flex-row">
      <div className="p-8 md:w-1/3 md:p-16">
        <Listen />
        <Weather />
        <Waves />
        <DealsOnWheels />
        <NicePlace />
      </div>
      <div className="p-8 md:w-1/3 md:p-16">
        <TodayInLA />
        <DirtyDogs />
      </div>
      <div className="p-8 md:w-1/3 md:p-16">
        <CoolPool />
        <DinersClub />
      </div>
    </div>
  </Layout>
)

export default IndexPage
