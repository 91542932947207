import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const NicePlace = () => {
  const {
    allAirtable: { nodes: places },
  } = useStaticQuery(graphql`
    query LatestPlaces {
      allAirtable(
        sort: { fields: data___Created_At, order: DESC }
        filter: { table: { eq: "Nice Place" } }
        limit: 4
      ) {
        nodes {
          data {
            URL
            Sqft
            Bedrooms
            Type
            Neighborhood
            Price
          }
        }
      }
    }
  `)

  return (
    <section>
      <h2>Nice Place</h2>
      {places.map(({ data: place }) => (
        <dl className="font-medium" key={`place-${place.URL}`}>
          <dt>
            <a className="underline" href={place.URL}>
              ({place.Bedrooms}br) {place.Sqft && `${place.Sqft}ft\u00B2`} in{" "}
              {place.Neighborhood}
            </a>
          </dt>
          <dd className="text-xs">${place.Price}</dd>
        </dl>
      ))}
    </section>
  )
}
export default NicePlace
