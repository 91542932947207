import React from "react"

const DirtyDogs = () => {
  return (
    <section>
      <h2>Dirty Dogs</h2>
      <img
        className="w-2/3 mb-1"
        src="https://goodtime.la/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBFQT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--12be88f4673d62b0b20993b139695b0dcc20c31d/image_0-1.jpg"
        alt="Dirty Dog"
      />
      <p className="text-xs font-medium">Text your dirty dog to 213-340-2665</p>
    </section>
  )
}
export default DirtyDogs
