import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const CoolPool = () => {
  const data = useStaticQuery(graphql`
    query FeaturedPool {
      airtable(data: { Featured: { eq: true } }, table: { eq: "Cool Pools" }) {
        data {
          Name
          Neighborhood
          Photos {
            url
          }
        }
      }
    }
  `)

  const {
    airtable: { data: pool },
  } = data

  return (
    <section>
      <h2>Cool Pool</h2>
      <img className="w-2/3 mb-1" src={pool.Photos[0].url} alt={pool.Name} />
      <strong className="font-medium">
        {pool.Name}, {pool.Neighborhood}
      </strong>
      <p className="text-xs">
        <a href={`http://maps.apple.com/?daddr=${pool.Address}`}>Directions</a>
      </p>
    </section>
  )
}
export default CoolPool
