import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const TodayInLA = () => {
  const {
    allAirtable: {
      nodes: [
        {
          data: {
            Photo: [today],
          },
        },
      ],
    },
  } = useStaticQuery(graphql`
    query TodayInLA {
      allAirtable(
        sort: { order: DESC, fields: data___Created_At }
        limit: 1
        filter: {
          data: { Approved: { eq: true } }
          table: { eq: "Today in LA" }
        }
      ) {
        nodes {
          data {
            Photo {
              url
            }
          }
        }
      }
    }
  `)

  return (
    <section>
      <h2>Today in LA</h2>
      <img className="w-3/4 mb-1" src={today.url} alt="Today in LA" />
      <p className="text-xs font-medium">Text your photos to 213-340-2665</p>
    </section>
  )
}
export default TodayInLA
