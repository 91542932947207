import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const DealsOnWheels = () => {
  const {
    allAirtable: { nodes: deals },
  } = useStaticQuery(graphql`
    query LatestDealsOnWheels {
      allAirtable(
        sort: { fields: data___Created_At, order: DESC }
        filter: { table: { eq: "Deals on Wheels" } }
        limit: 4
      ) {
        nodes {
          data {
            Neighborhood
            URL
            Make
            Model
            Year
          }
        }
      }
    }
  `)

  return (
    <section>
      <h2>Deals On Wheels</h2>
      {deals.map(({ data: deal }) => (
        <dl className="font-medium" key={`deal-${deal.URL}`}>
          <dt>
            <a className="underline" href={deal.URL}>
              {deal.Year} {deal.Make} {deal.Model}
            </a>
          </dt>
          <dd className="text-xs">{deal.Neighborhood}</dd>
        </dl>
      ))}
    </section>
  )
}

export default DealsOnWheels
