import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const DinersClub = () => {
  const data = useStaticQuery(graphql`
    query FeaturedDiner {
      airtable(data: { Featured: { eq: true } }, table: { eq: "Diners Club" }) {
        data {
          Name
          Neighborhood
          Photos {
            url
          }
        }
      }
    }
  `)

  const {
    airtable: { data: diner },
  } = data
  return (
    <section>
      <h2>Diners Club</h2>
      <img className="w-2/3 mb-1" src={diner.Photos[0].url} alt={diner.Name} />
      <strong className="font-medium">
        {diner.Name}, {diner.Neighborhood}
      </strong>
      <p className="text-xs">
        <a href={`http://maps.apple.com/?daddr=${diner.Address}`}>Directions</a>
      </p>
    </section>
  )
}

export default DinersClub
