import React, { useState } from "react"
import ReactHowler from "react-howler"

const Listen = () => {
  let [playing, setPlaying] = useState(false)

  return (
    <section>
      <h2>Listen</h2>
      <button className="italic" onClick={() => setPlaying(!playing)}>
        {playing ? "Pause" : "Play"}
      </button>
      <ReactHowler
        src={["https://audio.goodtime.la/radio/8000/radio.mp3?1589234843"]}
        playing={playing}
        html5={true}
        onLoadError={m => console.log(m)}
      />
    </section>
  )
}
export default Listen
