import React, { useState, useEffect } from "react"

const Waves = () => {
  const [report, setReport] = useState([])

  const fetchReport = () => {
    fetch("/.netlify/functions/waves")
      .then(res => res.json())
      .then(json => {
        setReport(json)
      })
  }
  useEffect(() => fetchReport(), [])

  return (
    <section>
      <h2>Waves</h2>
      <table className="w-ful">
        <tbody>
          {report.map(spot => (
            <tr key={`waves-${spot.name}`}>
              <td className="font-semibold pr-4">{spot.name}</td>
              <td className="text-righ">{spot.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  )
}
export default Waves
