import React, { useState, useEffect } from "react"

const Weather = () => {
  const [weather, setWeather] = useState()

  const fetchWeather = () => {
    fetch("/.netlify/functions/weather")
      .then(res => res.json())
      .then(json => {
        setWeather(json)
      })
  }
  useEffect(() => fetchWeather(), [])

  return (
    <section>
      <h2>
        {weather && (
          <>
            {parseInt(weather.main.temp)}&deg; and {weather.weather[0].main}
          </>
        )}
      </h2>
    </section>
  )
}
export default Weather
